import { LuClock4, LuMapPin, LuUsers } from "react-icons/lu";
import { wrapInAnimatedSpans } from "./util";

export function TicketCard(props) {
  const { name, description, date, time, location, artists, image, link } = props;

  return (
    <div className="mt-16 shadow-2xl">
      <img src={image} alt={name} className="" />
      <div className="p-4 bg-white bg-opacity-10 backdrop-saturate-150">
        <div className="flex flex-col gap-1">
          <div className="uppercase flex items-center gap-2 text-sm">
            <LuClock4 className="opacity-50" />
            {date}&nbsp;{time}
          </div>
          <div className="uppercase flex items-center gap-2 text-sm">
            <LuMapPin className="opacity-50" />
            {location}
          </div>
          <div className="uppercase flex items-start gap-2 text-sm md:w-3/4">
            <div>
              <LuUsers width={24} height={24} className="opacity-50" />
            </div>
            <div>{artists}</div>
          </div>
        </div>
        <h3 className="text-xl font-formaBold mt-6">{name}</h3>
        <p className="opacity-70">{description}</p>
        <a
          href={link}
          className="mt-8 block border border-white animate-button hover:border-opacity-50 transition-all"
          target="_blank"
          rel="noreferrer"
        >
          <div className="overflow-hidden flex justify-center py-4">{wrapInAnimatedSpans("Get Your Tickets")}</div>
        </a>
      </div>
    </div>
  );
}
