import "./App.css";
import Links from "./Links";
import ParticlesWrapper from "./Particles";
import { wrapInAnimatedSpans } from "./util";

import bamboo from "../src/img/bamboo.jpg";
import simplon from "../src/img/simplon.jpg";
import { TicketCard } from "./TicketCard";

function App() {
  return (
    <>
      <ParticlesWrapper />
      <main className="relative font-forma min-h-screen max-w-xl mx-auto px-2 py-28">
        <h1 className="text-5xl lg:text-7xl font-formaExtraBold">
          Palla <span className="font-formaMediumItalic">d'Uovo</span>
        </h1>
        <p className="text-lg mt-4">
          <span className="font-formaMediumItalic">"Palla d'Uovo"</span> — A musical concept based around synth-heavy music genres such as Italo, EBM,
          and Dark Disco. Events, hostings, radio shows, guest mixes, and more.
        </p>
        <TicketCard
          image={bamboo}
          name="ODP x PDU King's Day"
          date="26 APR"
          time="13:00 - 23:00"
          location="Bamboo Lodge, Groningen"
          artists="Krismika, Vasco, Lotus, Jens Bakker, DJ Firetower, Roeland, Elias, Tarnim, Polly"
          description="Join us for a special King's Day event at Bamboo Lodge! Featuring a lineup of amazing artists including Krismika, Vasco, Lotus, Jens Bakker, DJ Firetower, Roeland, Elias, Tarnim, and Polly."
          link="https://odp-events.nl/tickets"
        />
        <TicketCard
          image={simplon}
          name="Palla d'Uovo Club Night"
          date="23 MAY"
          time="23:59 - 05:00"
          location="Simplon, Groningen"
          artists="Iris Rijskamp B2B Looming Iris, Polly"
          description="Join us for the third installment of our Simplon club night! This time we have a special B2B set from Iris Rijskamp and Looming Iris, along with Polly."
          link="https://simplon.stager.co/web/tickets/111448759"
        />
        {/* <div className="ticket-wrap mt-16">
          <div className="ticket ticket--top text-white px-4 pt-4 pb-6">
            <div className="-mx-4 -mt-4 mb-4">
              <img src={bamboo} alt="Bamboo Lodge" className="opacity-80 rounded-t-lg" />
            </div>
            <h2 className="text-xl tracking-wide font-formaBold pb-2 border-b border-black border-opacity-20">ODP x PDU King's Day</h2>
            <div className="mt-4 flex gap-6">
              <div className="w-1/2">
                <p className="text-white opacity-30 font-formaItalic">Date</p>
                <p className="text-lg tracking-wide">April 26th, 2025</p>
              </div>
              <div className="w-1/2">
                <p className="text-text-white opacity-30 font-formaItalic">Time</p>
                <p className="text-lg tracking-wide">13:00 - 23:00</p>
              </div>
            </div>
            <div className="mt-4 flex gap-6">
              <div>
                <p className="text-text-white opacity-30 font-formaItalic">Location</p>
                <p className="text-lg tracking-wide">Bamboo Lodge, Groningen</p>
              </div>
            </div>
            <div className="mt-4 flex gap-6">
              <div>
                <p className="text-text-white opacity-30 font-formaItalic">Artists</p>
                <p className="text-lg tracking-wide">
                  Krismika, Vasco, Lotus, Jens Bakker, <br />
                  DJ Firetower, Roeland, Elias, Tarnim, Polly
                </p>
              </div>
            </div>
          </div>
          <div className="ticket ticket--bottom p-4">
            <a
              href="https://odp-events.nl/tickets"
              className="ticket-button block rounded-lg bg-purple-400 text-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex justify-center py-4">{wrapInAnimatedSpans("Get Your Tickets")}</div>
            </a>
          </div>
        </div> */}
        {/* <h2 className="font-formaExtraBold text-2xl tracking-wide mt-16">Events</h2>
        <div className="mt-4 flex gap-6 justify-between items-center text-lg">
          <div className="flex flex-col items-center">
            <p className="font-formaExtraBold">28</p>
            <p className="text-sm">FEB</p>
          </div>
          <div className="flex-grow">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://simplon.stager.co/web/tickets/111448759"
              className="underline font-formaExtraBold tracking-wide hover:opacity-50 transition-opacity"
            >
              Palla d'Uovo Club Night
            </a>
            <p className="text-sm">
              23:59 - 05:00
              <span className="hidden md:inline-block">,&nbsp;</span>
              <br className="md:hidden" />
              Simplon, Groningen
            </p>
          </div>
          <div className="overflow-hidden">
            <a target="_blank" rel="noreferrer" href="https://simplon.stager.co/web/tickets/111448759" className="uppercase text-base animate-button">
              <div>{wrapInAnimatedSpans("tickets")}</div>
            </a>
          </div>
        </div> */}
        <h2 className="font-formaExtraBold text-2xl tracking-wide mt-16">What's new?</h2>
        <Links />
        <h2 className="font-formaExtraBold text-2xl tracking-wide mt-16">Find us here</h2>
        <ul className="mt-4 flex flex-col gap-4 text-center">
          <li>
            <a
              href="https://chat.whatsapp.com/Lr2qZvh3u3m8D01rU99pST"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("Join our Community")}</div>
            </a>
          </li>
          <li>
            <a
              href="https://soundcloud.com/palla-duovo"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("SoundCloud")}</div>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/palladuovo/"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("Instagram")}</div>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@palladuovo"
              className="block border border-white animate-button hover:border-opacity-50 transition-all"
              target="_blank"
              rel="noreferrer"
            >
              <div className="overflow-hidden flex py-4 pl-4">{wrapInAnimatedSpans("YouTube")}</div>
            </a>
          </li>
          <li>
            <div className="block border border-white animate-button hover:border-opacity-50 transition-all">
              <p className="overflow-hidden flex py-4 pl-4">info@palladuovo.com</p>
            </div>
          </li>
        </ul>

        {/* <ul className="mt-16 flex flex-col gap-4 text-center text-xl">
          {links.map((link) => {
            const { name, url, buttonText } = link;

            return (
              <li key={name}>
                <a
                  href={url}
                  className="block border border-white animate-button hover:border-opacity-50 transition-all"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overflow-hidden flex justify-center py-4">{wrapInAnimatedSpans(buttonText)}</div>
                </a>
              </li>
            );
          })}
        </ul> */}
      </main>
    </>
  );
}

export default App;
