import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

export default function ParticlesWrapper() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  const isMobileViewport = window.screen.width <= 480;
  const particleColor = "#734010";

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          opacity: 0,
        },
        fpsLimit: 60,
        particles: {
          rotate: {
            random: {
              enable: true,
            },
            value: 0,
            animation: {
              enable: true,
              speed: isMobileViewport ? 5 : 3,
              decay: 0,
              sync: false,
            },
            direction: "random",
          },
          color: {
            // value: ["#f97316", "#265fdc"],
            // value: ["#265fdc","#000000"]
            // value: ["#fc7b74", "#000000"],
            // value: ["#ec443c", "#000000"],
            // value: ["#5b12c8", "#000000"],
            value: [particleColor, "#000000"],
          },
          collisions: {
            enable: true,
          },
          move: {
            enable: true,
            directions: "random",
            outModes: {
              default: "out",
            },
            random: false,
            speed: 1,
            trail: {
              enable: true,
              length: 100,
              fill: {
                // color: ["#f97316", "#f97316", "#265fdc"],
                // value: ["#000000", "#fc7b74"],
                // color: ["#000000", "#fc7b74"],
                // color: ["#5b12c8", "#000000"],
                color: [particleColor, "#000000"],
              },
            },
            spin: {
              enable: true,
            },
          },
          number: {
            value: 15,
          },
          shape: {
            type: "triangle",
          },
          size: {
            value: { min: isMobileViewport ? 50 : 200, max: isMobileViewport ? 250 : 700 },
          },
        },
        detectRetina: true,
      }}
    />
  );
}
